.card-box {
    height: 100%;
    border-radius: 1px solid grey;
    display: flex;
    flex-direction: column;
    display: flex;
    cursor: pointer;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.card-box:hover .card-icon-detail {
    color: blue;
}

.card-box:hover .card-detail {
    color: orangered
}

.card-img{
    min-height: 200px;
    max-height: 200px;  
    position: relative;
}

.close-text {
    background-color: #51557E;
    position: absolute;
    bottom: 0;
    padding: 10px;
    text-align: center;
    color: white;
    font-weight: bold;
    border-top-right-radius: 4px; 
    color: white;
}

.card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    max-width: 200px;
    height: 20px;

}

.card-content {
    display: flex;
    justify-content: space-between;
}

.card-local {
    display: flex;
    font-size: 12px;
    align-items: center;
    gap: 4px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}

.card-local .card-text-local {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.card-detail {
    font-size: 12px;
    cursor: pointer;
    color: silver;
    font-weight: bold;
    display: flex;
    border-radius: 3px;
    gap: 5px;
}


.card-star-box {
    background-color: black;
    opacity: 0.7;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-top-left-radius: 3px;
}


.card-menu-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    z-index: 20;
    overflow-y: scroll;
}

.showCardMenu {
    display: flex;
    opacity: 100;
    flex-direction: column;
    background-color: white;
    z-index: 20;
    overflow-x: hidden;
    overflow-y: auto;
}


.card-menu-item {
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px;
    min-height: 25px;
    text-decoration: none;
    border-bottom: 1px solid gray;
    display: flex;
    align-items: center;
}

.card-menu-item:hover {
    background-color: brown;
    color: white;
}


@media only screen and (max-width: 500px){
    .card-title {
        font-size: 10px;
        margin-top: 10px;
    }
}