.AppBar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid gray;
    position: relative;
    background: white;
}

.AppBar-Logo {
    width: 150px;
}

.AppBar-Btn {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}


.AppBar-Btn:hover {
    background: #0E5E6F;
    color: white;
}

.AppBar-Btn .appbar-link {
    color: black;
}

.AppBar-Btn:hover .appbar-link{
    background: #0E5E6F;
    color: white;
}

.AppBar-Input {
    width: 50%;
    display: flex;
    align-self: center;
    justify-content: center;
    height: 45px;
    position: relative;
}

.AppBar-Help {
    display: flex;
    flex-direction: column;
}

.AppBar-Input input {
    width: 100%;
    border-radius: 50px;
    outline: none;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 50px;
    border: 1px solid gray;
}

.AppBar-Search {
    font-size: 30px;
    position: absolute;
    right: 10px;
    top: 6.5px;
    cursor: pointer;
}

.AppBar-Btn-Auth {
    position: absolute;
    flex-direction: column;
    box-shadow: 0 0 25px 25px rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    top: 40px;
    left: -50px;
    transition: all 0.5s;
    background: white;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
}

.AppBar-Person {
    color: gray;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 130px;
    padding-left: 10px;
    padding-right: 10px;
}

.AppBar-Person:hover {
    text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3);    
    color: #FF577F
}

.AppBar-Btn-Account:hover .AppBar-Btn-Auth{
    display: flex;
    opacity: 100;
    visibility: visible;
    pointer-events: all;
}

.appbar-mobile-box {
    width: 100%;
    display: flex;
    font-size: 20px;
    padding: 5px;
    justify-content: space-between;
}

.AppBar-notification {
    width: 500px;
    height: 100%;
    position: fixed;
    border: 1px solid gray;
    background-color: #EEEEEE;
    z-index: 200;
    top: 0;
    right: 0;
    transition: all 0.5s;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.AppBar-notification-content-box {
    overflow-y: auto;
    flex: 1;
}
.AppBar-notification-item {
    padding: 5px;
    text-align: left;
    border-bottom: 1px solid gray;
}

.AppBar-notification-close {
    padding: 10px;
    background-color: brown;
    color: white;
    cursor: pointer;
    text-align: center;
}

.AppBar-notification-close-icon {
    position: absolute;
    top: 0;
    left: -45px;
    z-index: 200;
    cursor: pointer;
    color: #FB2576;
}

.shop-detail-top-img {
    position: relative;
}

.shop-detail-favorite {
    position: absolute;
    right: 6px;
    bottom: 6px;
    cursor: pointer;
    background-color: lavender;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AppBar-favorite-item {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 10px;
    align-items: center;
}

.AppBar-favorite-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    text-decoration: none;
    position: relative;
}

.appbar-mobile-text {
    font-weight: bold;
    color: purple;
}

.appbar-mobile-menu {
    cursor: pointer;
}

.appbar-mobile-box {
    display: none;
    visibility: hidden;
    opacity: 0;
}

.appbar-mobile-items {
    display: flex;
    position: fixed;
    flex-direction: column;
    top: 0;
    right: 0;
    z-index: 50;
    background-color: #EEEEEE;
    height: 100vh;
    padding: 5px;
    box-sizing: border-box;
    max-width: 400px;
    width: 100%;
    justify-content: space-between;
}

.appBar-mobile-item {
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 16px;
    text-decoration: none;
    background: #5BC0F8;
    color: white;
    cursor: pointer;
    position: relative;
    margin: 5px;
    border-radius: 4px;
}

.appbar-mobile-close {
    width: 100%;
    padding: 8px;
    cursor: pointer;
    background: #3F0071;
    color: white;
    border: none;
    border-radius: 20px;
}

@media only screen and (max-width: 1200px) {
    .AppBar-Logo {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    .AppBar-Btn {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    .AppBar-Input {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    .AppBar-Help
    { 
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    .appbar-mobile-box {
        display: flex;
        visibility: visible;
        opacity: 1;
    }

    .appbar-mobile-box {
        display: flex;
        visibility: visible;
        opacity: 1;
    }
}

@media only screen and (max-width: 800px) {
    .AppBar-notification {
        width: 80%;
    }
}
