.AddProduct{
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.AddProduct-btn {
    display: flex;
    width: 100%;
    justify-content: start;
    gap: 10px
}

.AddProduct-btn-menu {
    display: flex;
    gap: 5px;
    text-align: center;
    width: 15%;
    padding: 10px;
    border: 1px solid gray;
    margin-top: 5px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    height: 25px;
    justify-content: space-between;
    position: relative;
}

.AddProduct-add-menu {
    display: flex;
    gap: 5px;
    text-align: center;
    width: 20%;
    padding: 10px;
    border: 1px solid gray;
    margin-top: 5px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    height: 25px;
    position: relative;
}

.AddProduct-add-menu span {
    width: 100%;
    text-align: left;
    font-weight: bold;
    color: black;
}

.AddProduct-add-menu input {
    height: 25px;
    width: 90%;
    outline: none;
    border: none;
    border-bottom: 1px solid black;
}

.AddProduct-hr {
    width: 100%;
}

.AddProduct-sp{
    width: 100%;
    text-align: left;
}

.addproduct-loading {
    display: flex;
    width: 100%;
    height: 80vh;
    align-items: center;
    justify-content: center;
}

.addproduct-loading .addproduct-icon-loading {
    font-size: 40px;
    animation: rotate 2s linear infinite;
}

.addproduct-menu-box {
    width: 100%;
    height: 300px;
    background-color: #153462;
    position: absolute;
    top: 50px;
    left: 0;
    border-radius: 4px;
    transform-origin: top;
    transition: all 0.5s;
    color:white;
    padding: 5px;
    overflow-y: auto;
    z-index: 100;
}

.addproduct-menu-item {
    width: 85%;
    text-align: left;
    padding: 5px;
    transition: all 0.5s;
}

.addproduct-menu-item:hover {
    border-radius: 4px;
    font-weight: bold;
    transform: scale(1.1);
}

.addproduct-items-box {
    display: flex;
    gap: 10px;
    width: 98%;
    flex-wrap: wrap;
 
}

.addproduct-add-btn {
    flex: 1;
    min-width: 200px;
    max-width: 200px;
    border: 1px solid gray;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
    gap: 5px;
    position: relative;
}


.addproduct-image {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.5s;
    transform-origin: top;
}

.addproduct-image img{
    width: 100%;
    height: 100%;
    position: absolute;
}

.addproduct-input {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
}

.addproduct-input input {
    height: 20px;
    width: 100%;
    outline: none;

}

.addproduct-submit {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    cursor: pointer;
}

.addproduct-submit:hover {
   background-color: #404258;
   outline: none;
   border-radius: 3px;
   color: white;
}

.addproduct-image-input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.addproduct-image-food {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.addproduct-image-food img {
    object-fit: contain;
}

.addproduct-box-btn {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    width: 100%;
}

.addproduct-icon-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 3px;
}

.addproduct-icon-btn:hover {
    background-color: #404258;
    color: white;
    outline: none;
    border-radius: 3px;
}

.addproduct-update-btn {
    cursor: pointer;
    flex: 1;
    padding: 5px
}

.addproduct-update-btn:hover {
    background-color: #404258;
    color: white;
    outline: none;
    border-radius: 3px;
}

.delete-popup {
    width: 40%;
    height: 150px;
    padding: 10px;
    border: 1px solid gray;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #474E68;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: white;
    z-index: 100;
}

.addproduct-delete-btn {
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.addproduct-delete-btn p {
    width: 50%;
    text-align: center;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 6px;
    cursor: pointer;
}

.addproduct-delete-btn p:hover {
    background-color: #5F9DF7
}

.addproduct-pl-box {
    width: 96%;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid gray;
    cursor: pointer;
    font-size: 14px;
}

.addproduct-pl-list {
    position: absolute;
    height: 250px;
    width: 97%;
    align-self: center;
    top: 0;
    left: 0;
    background-color: #9CB4CC;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    gap: 5px;
    overflow: hidden;
    overflow-y: auto;
    height: 98%;
    transition: all 0.5s;
}

.addproduct-pl-list input {
    outline: none;
    height: 25px;
    min-height: 25px;
    width: 97%;
}

.addproduct-pl-list button {
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid gray;
    width: 90%;
    border-radius: 20px;

}

.addproduct-pl-list button:hover {
    background-color: #404258;
    color: white;
}

.addproduct-pl-item{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
    color: white;
    flex-direction: column;
    border-radius: 4px;
}

.addproduct-pl-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.pl-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3px;
    background-color: white;
}

.pl-item span:nth-child(1) {
    font-weight: bold;
    color: gray;
}

.pl-item span:nth-child(2) {
    font-weight: bold;
    color: #3330E4
}

.pl-delete {
    text-align: center;
    padding: 3px;
    background-color: #404258;
    width: 100%;
}