.myshop-box {
    display: flex;
    flex-direction: column;
    padding: 5px;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.myshop-info-top-box{
    display: flex;
    flex-wrap: wrap;
}

.myshop-anhdaidien{
    width: 400px;
    height: 400px;
    border-radius: 5px;
    border: 1px solid gray;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.myshop-anhdaidien button {
    width: 50%;
    padding: 15px 0;
    cursor: pointer;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.myshop-input-anhdaidien {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.myshop-input-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    flex: 1;
    justify-content: flex-start;
    align-content: flex-start;
}

.myshop-input-box {
    width: 90%;
    margin-top: 5px;
    position: relative;
}

.myshop-input-box label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: gray;
}

.myshop-input-box input {
    height: 30px;
    margin-top: 5px;
    outline: none;
    width: 100%;
}

.myshop-input-box-2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 5px;
    gap: 20px;
    position: relative;
}

.myshop-input-box-2-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.myshop-input-box-2-1 label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: gray;
}

.myshop-input-box-2-1 input {
    height: 30px;
    margin-top: 5px;
    outline: none;
    width: 100%;
}

.myshop-input-img-cc {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 266px;
    gap: 5px;
    color: gray;
    font-weight: bold;
    position: relative;
    cursor: pointer;
}

.myshop-input-img-cc label {
    width: 100%;
    text-align: left
}

.myshop-input-img-cc div {
    height: 100%;
    border: 1px solid gray;
    border-radius: 2px;
    height: 250px;
    position: relative;
}

.myshop-input-img-cc div input{
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
}

.myshop-upload-cc-icon {
    position: absolute;
    font-size: 70px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.img-cc {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
}

.myshop-local-container {
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    margin-top: 10px;
    width: 95%;
    padding: 10px;
    padding-left: 0;
}

.myshop-local-box {
    width: 30%;
    margin-right: 20px;
    cursor: pointer;
}

.myshop-local-box label {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    color: gray;
    font-weight: bold;
}

.myshop-local-box div {
    width: 100%;
    height: 30px;
    outline: none;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
}

.myshop-address-keyword {
    display: flex;
    width: 95%;
    gap: 10px;
}

.myshop-address-keyword div {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.myshop-address-keyword div label{
    width: 50%;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
    font-weight: bold;
    color: gray;
}

.myshop-address-keyword div input {
    height: 30px;
    outline: none;
}

.time-open {
    margin-top: 10px;
    width: 100%;
    text-align: left;
    font-weight: bold;
}

.local-box {
    position: relative;
    width: 100%;
}

.myshop-local-dropdown-box{
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    background-color: #3F3B6C;
    color: white;
    border: 10px solid #8D9EFF;
    border-radius: 3px;
    height: 70%;
    z-index: 10;
    padding: 10px;
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    cursor: pointer;
}

.myshop-local-dropdown-box p {
    padding: 5px;
    width: 95%;
    text-align: left;
}

.myshop-local-dropdown-box p:hover {
    background-color: white;
    color: black;
}

.myshop-open-time-box {
    display: flex;
    justify-content: space-start;
    width: 100%;
    padding: 5px;
    gap: 20px;
}

.myshop-open-time-box div {
    flex: 1;
    text-align: left;
    font-weight: bold;
    color: gray;
    width: 80%;
    
}

.myshop-time-input-box input {
    outline: none;
}

.myshop-time {
    display: flex;
    position: relative;
    border: 1px solid gray;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    padding: 3px;
    width: 100%;
}

.myshop-time span {
    flex: 1;
    cursor: pointer;
}

.myshop-dropdown-time {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #3F3B6C;
    color: white;
    z-index: 10;
    padding: 5px;
    gap: 5px;
    overflow-y: scroll;
    height: 200px;
}

.myshop-dropdown-time div {
    color: white;
    cursor: pointer;
    padding: 3px;
    z-index: 100;
}

.myshop-dropdown-time div:hover {
    color: orange;
}

.mytime-open-to{
   background-image: red
}

.myshop-btn-dkbh{
    width: 50%;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background-color: #80489C;
    color: white;
    transition: all 0.5s;
}

.myshop-btn-dkbh:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5)
}

.myshop-message {
    position: fixed;
    width: 50%;
    height: 50%;
    border: 1px solid gray;
    top: 50%;
    left: 60%;
    background-color: #50577A;
    z-index: 10;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.myshop-message-content {
    width: 80%;
    color: orange;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myshop-close-message {
    border: 1px solid gray;
    padding: 10px 5px;
    width: 80%;
    border-radius: 20px;
    background-color: #6C4AB6;
    color: white;
    cursor: pointer;
    text-align: center;
}

.myshop-description {
    width: 95%;
    margin-right: 10px;
    outline: none;
    padding: 5px;
}

.myshop-terms-container {
    width: 100%;
    height: 100vh;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
}

.myshop-free {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    font-weight: bold;
}

.myshop-ship-item {
    border-radius: 3px;
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
}

.myshop-ship-item input {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-right: 5px;
    padding: 5px;
    width: 100%;
    outline: none;
}

.myshop-terms-box {
    display: flex;
    flex-direction: column;
    width: 70%;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid gray;
    overflow-y: scroll;
    margin: 10px;
    height: 500px;
    padding: 10px;
}

.myshop-terms-content {
    padding: 10px;
    text-align: left;
    line-height: 25px;
}

.myshop-loading-box {  
    margin-top: 300px;
    margin-bottom: 300px;
}

.readTerm-btn{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 100px;
}

.readTerm-btn p {
    text-align: center;
}

.readTerm-btn p{
    padding: 20px;
    width: 20%;
    border-radius: 4px;
}

.readTerm-btn p:nth-child(1) {
    border: 2px solid #E97777;
}

.readTerm-btn p:nth-child(2) {
    background-color: #8D9EFF;
    color: white
}

.myshop-ship-box{
    display: flex;
    width: 95%;
    justify-content: space-between;
}

.myshop-ship-box p {
    display: flex;
    align-items: center;
    border: 1px solid gray;
    padding: 15px;
    height: 40;
    gap: 15px;
    cursor: pointer;
    position: relative;
}

.myshop-ship-box p input{
    height: 15px;
    width: 15px;
    cursor: pointer;
    position: relative;
}

.myshop-cost-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    background-color: #8D9EFF;
    width: 100%;
    align-items: start;
    padding: 10px;
    gap: 10px;
    font-weight: bold;
    z-index: 20;
    flex: 1;
}

.myshop-cost-dropdown span {
    color: white
}

.myshop-cost-dropdown span:hover {
    color: #E14D2A
}

.ktt {
    position: absolute;
    padding: 5px;
    top: 10.5px;
    right: -5px;
    border: 1px solid gray;
    color: white;
    background-color: #6B728E;
    border-radius: 3px;
    cursor: pointer;
}

.myshop-guide-map-box{
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-top: 10px;
    align-items: center;
}

.myshop-guide-map-box div {
    display: flex;
    gap: 5px;
    align-self: center;
}

.myshop-guide-map {
    font-weight: bold;
    cursor: pointer;
    color: #432C7A
}

.myshop-guide-map-platform {
    text-transform: uppercase;
    font-weight: bold;
    color: blue;
}

.myshop-guide-map-platform:hover {
    color: orangered;
    cursor: pointer;
}

.myshop-notification {
    display: flex;
    flex-direction: column;
    width: 95%;
    justify-content: flex-start;
    text-align: left;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.myshop-notification span {
    font-weight: bold;
}

.myshop-notification input {
    height: 30px;
    outline: none;
}

