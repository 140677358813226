.search-list-box {
    width: 100%;
    height: 500px;
    background-color: #256D85;
    position: absolute;
    top: 48px;
    z-index: 50;
    border-radius: 5px;
    transition: all 0.5s;
    padding: 5px;
    box-sizing: border-box;
    transform-origin: top;
}

.search-list-item {
    width: 100%;
    display: block;
    text-align: left;
    cursor: pointer;
    padding: 5px;
    transition: all 0.5s;
    box-sizing: border-box;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-list-item:hover {
    background-color: white;
    border-radius: 4px;
    color: black;
}

.search-list-box span:hover {
    background-color: white;
    border-radius: 4px;
    color: black;
}