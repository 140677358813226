.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    animation: loadrotate 2s infinite linear;
    display: flex;
    justify-content: center;
    align-self: center;
}

@keyframes loadrotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}