.register {
    border: 1px solid gray;
    padding: 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 500px;
}

h5 span {
    color: green;
    cursor: pointer;
}

.register-input-box{
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: flex-start
}

.register-input-box label {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
}

.register-input-box input {
    height: 35px;
    width: 100%;
    outline: none;
    border: 1px solid gray;
    padding-left: 5px;
    margin-bottom: 5px;
}

.register-btn {
    padding: 10px;
    width: 95%;
    margin: auto;
    cursor: pointer;
    border: 1px solid gray;
    background-color: #6C4AB6;
    color: white;
    transition: all 0.5s;
    border-radius: 25px;
    text-align: center;
}

.register-btn:hover {
    box-shadow: 0 0 5px 5px rgba(152, 111, 222, 0.5);
}

.register-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.register-gf {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between
}

.register-gf GoogleLogin{
    width: 100%;
}

@media only screen and (max-width: 550px) {
    .register {
        max-width: 90%;
    }
}

