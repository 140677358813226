.km-text-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
    border-radius: 4px;
}

.km-text {
    text-align: center;
    border-radius: 3px;
    width: max-content;
    cursor: pointer;
    color: gray;
    position: relative;
    height: 100%;
    -webkit-text-stroke: 1px gray;
    text-transform: uppercase;
    height: 100%;
    text-align: center;
}

.km-text::before {
    content: attr(data-text);
    position: absolute;
    width: 0;
    height: 100%;
    color: white;
    top: 0;
    left: 0;
    -webkit-text-stroke: 0 gray;
    border-right: 2px solid white;
    white-space: nowrap;
    overflow: hidden;
    animation: atext linear 6s infinite
}

@keyframes atext {
    0%, 10%, 100% {
        width: 0;
    }
    70%, 90% {
        width: 100%;
    }
}