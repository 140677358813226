.Products-Box {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.Product-Btn {
    display: flex;
    width: 98%;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 5px;
    margin: 5px;
    flex-wrap: wrap;
    text-align: center;
}

.Product-Search {
    min-width: 150px;
    max-width: 200px;
    position: relative;
    margin-right: 10px;
}

.Product-Search-Icon {
  width: 0;
  display: none;
}

.Product-Search input {
    height: 30px;
    outline: none;
    border-radius: 20px;
    border: 1px solid gray;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 200px;
}


.product-btn-item {
    padding: 5px;
    border: 1px solid gray;
    flex: 1;
    margin: 5px;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 150px;
    max-width: 200px;
}

.Product-Items {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    flex-wrap: wrap;
    gap: 5px;
    position: relative;
}

.product-item {
    padding: 5px;
    border: 1px solid gray;
    border-radius: 3px;
    transition: all 0.5s;
    max-width: 45%;
}

.product-pages-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    gap: 30px;
    align-items: center;
    box-sizing: border-box;
}

.product-pages-item {
    padding: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
}
.product-pages-item:hover {
    box-shadow: 0 0 5px 5px rgba(112, 142, 221, 0.5)
}

.product-arrow-page {
    display: flex;
    align-items: center;
    font-size: 40px;
    color: gray;
    cursor: pointer;
    transition: all 0.5s;
}

.product-arrow-page:hover {
    color: dodgerblue;
    box-shadow: 0 0 5px 5px rgba(112, 142, 221, 0.5)
}

.card-icons-box {
    display: flex;
    gap: 5px;
    align-items: center;
}

.card-icons-box span {
    color: gray;
}

.card-icons-box span:nth-child(1):hover {
    color: orangered;
}

.product-loading {
    min-height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-loading-icon {
    animation: rotate 2s linear infinite;
}

.product-btn-local {
    flex: 1;
    border: 1px solid gray;
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    justify-content: space-between;
    cursor: pointer;
    gap: 5px;
    min-width: 150px;
    max-width: 200px;
    font-size: 14px;
    position: relative;
}

.product-local-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    width: 100%;
}

.product-dropdown-local {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    top: 32px;
    left: 0;
    z-index: 20;
    background-color: white;
    width: 100%;
    gap: 5px;
    padding: 5px;
    border: 2px solid #9FB4FF;
    border-radius: 4px;
}

.product-dropdown-local-item {
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid gray;
    text-decoration: none;
}

.product-dropdown-local-item:hover {
    color: orangered;
}

@media only screen and (max-width: 800px) {
    .product-pages-item {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    .product-pages-btn {
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media only screen and (min-width: 1000px) {
    .product-item:hover {
        transform: scale(1.05);
        z-index: 10;
        background-color: white;
    }    
}

@media only screen and (max-width: 500px) {
    .product-dropdown-local {
       position: fixed;
       width: 90%;
       height: 90%;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       overflow: scroll;
    }
}