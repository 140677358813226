.sound-open {
    position: fixed;
    width: 70px;
    height: 70px;
    display: flex;
    color: white;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 20px;
    bottom: 20px;
    z-index: 100;
}

