
.cart-screen {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    display: flex;
    flex-direction: column
}

.cart-order {
    padding: 15px;
}

.cart-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
}

.cart-btn-back {
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #FF5858;
    border: none;
    border-radius: 4px;
    color: white;
}

.cart-list-items {
    width: 80%;
    margin-top: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: auto;
}

.cart-order {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 5px;
    padding: 15px;
    box-sizing: border-box;
}

.cart-order-img {
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
}

.cart-order-item {
    display: flex;
    width: 100%;
    border: 1px solid gray; 
    margin-right: 10px;
    border-radius: 3px;

}

.cart-order-content-box {
    flex: 1;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    box-sizing: border-box;
    position: relative;
}

.cart-order-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;

}   

.cart-order-content span:nth-child(1) {
    font-weight: bold;
    color: gray;
}

.cart-order-content span:nth-child(2) {
    font-weight: bold;
}

.cart-order-mt {
    cursor: pointer;
}

.cart-order-bot-info {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.cart-btn-container {
    display: flex;
    width: 100%;
    gap: 15px;
    margin-top: 5px;
    height: 60px;
}

.cart-btn-submit {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    background-color: #5C2E7E;
    color: white;
    transition: all 0.5s;
    border-radius: 30px;
    flex: 1;
}

.cart-btn-submit-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    box-sizing: border-box;
    font-weight: bold;
    text-transform: uppercase;

}

.cart-btn-submit:hover{
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.7);
    transform: translateY(-2px);

}

.cart-btn-submit-icon-right {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightcoral;
    color: white;
    width: 30%;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    clip-path: polygon(30% 0, 100% 0, 100% 100%, 30% 100%, 0% 50%);
    text-transform: uppercase;
}

.cart-btn-submit-icon-left {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightcoral;
    color: white;
    width: 30%;
    height: 100%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    clip-path: polygon(0 0, 70% 0, 100% 50%, 100% 50%, 70% 100%, 0 100%);
    text-transform: uppercase;
}

.cart-text-overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cart-nameshop {
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
}

.cart-mbs-box {
    display: flex;
    position: absolute;
    width: 98%;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    background-color: white;
    transform-origin: top;
    transition: all 0.5s;
    justify-content: space-between;
    box-sizing: border-box;
    overflow-y: auto;
    gap: 5px;
}

.cart-mbs-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
}

.cart-mbs-close {
    width: 99%;
    background-color: #FF85B3;
    padding: 5px;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    margin: 5px auto;
    border-radius: 15px

}

.cart-mbs-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid gray;
    padding: 5px;
    gap: 10px;
}

.cart-text-break {
    word-break: break-all;
}

.cart-order-message {
    width: 400px;
    height: 100px;
    position: fixed;
    background-color: #749F82;
    bottom: 0;
    right: 0;
    z-index: 100;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    align-items: flex-end;
    cursor: pointer;
    transform-origin: bottom right;
}

.cart-message-text {
    width: 100%;
    text-align: center;
}

@media (max-width: 800px) {
    .cart-order-item {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .cart-order-content-box {
        width: 100%;
    }

    .cart-btn-container {
        flex-direction: column;
    }

    .cart-list-items {
        width: 100%;
    }

    .cart-box {
        width: 100%;
    }

    .cart-screen {
        width: 100%;
    }



    .cart-btn-submit {
       background: #FF5858;
    }
}