.profile-box{
    display: flex;
    position: relative;
    align-items: flex-start;
}

.profile-box-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    background-color: #0081C9;
    width: 15%;
    border-right: 1px solid gray;
    position: relative;
    overflow: hidden;
    min-width: 35px;
    max-width: 200px;
}

.profile-btn-box {
    display: flex;
    align-self: flex-start;
    gap: 10px;
    cursor: pointer;
    color: white;
    width: 100%;
    overflow: hidden;
}

.profile-btn-box p {
    display: flex;
    font-size: 25px;
}

.profile-btn-box h4 {
    display: flex;
    align-items: center;
}

.profile-current-btn {
    width: 100%;
    background-color: white;
    color: black;
    position: absolute;
    top: 80px;
    left: 0;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    padding: 0;
    margin: 0;
    display: flex;
    align-self: flex-start;
    width: 100%;
    gap: 10px;
    padding-left: 30px;
    transition: all 0.5s;
}

.profile-current-btn p {
    display: flex;
    font-size: 25px;
}

.profile-current-btn h4 {
    display: flex;
    align-items: center;
}

.profile-box-right {
    flex: 1;
    position: relative;
}

.profile-info-locals-box {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-overflow-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
}

.profile-menu-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 50%;
    display: none;
    visibility: hidden;
    opacity: 0;
    align-items: center;
    justify-content: center;
    background-color: #383838;
}

.profile-menu-icon-items-down {
    position: absolute;
    top: 70px;
    left: 10px;
    width: 50px;
    min-height: max-content;
    color: white;
    background-color: #383838;
    font-size: 35px;
    border-radius: 3px;
    gap: 10px;
    transition: all 0.5s;
    transform-origin: top;
    overflow: auto;
}

@media (max-width: 1000px) {
    .profile-current-btn {
        opacity: 0;
        display: block none;
        visibility: hidden;
    }
}

@media (max-width: 800px) {
    .profile-btn-box h4 {
        display: none;
    }
    .profile-box-left {
        width: 5%;
    }
}

@media (max-width: 500px) {
    .profile-box-left {
        display: none;
        visibility: hidden;
        opacity: 0;
        width: 0;
    }
    .profile-box-right  {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile-menu-icon {
        display: flex;
        visibility: visible;
        opacity: 1;
    }
}