.Login-Box {
    padding: 20px;
    border: 1px solid gray;
    border-radius: 6px;
    overflow: hidden;
    min-width: 500px;
}

.Login-input-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    margin-bottom: 10px;
    width: 95;
}

.Login-input-box label {
    text-transform: uppercase;
    font-weight: bold;
}

.Login-input-box input {
    height: 35px;
    width: 100%;
    outline: none;
    padding-left: 5px;
}

.Login-remember {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.Login-btn {
    width: 98%;
    padding: 10px;
    border: none;
    background-color: #FF577F;
    color: white;
    outline: none;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.5s;
    text-align: center;
}

.Login-btn:hover {
    box-shadow: 0 0 5px 5px rgba(222, 111, 111, 0.7);
}

.Login-forgot {
    width: 100%;
    text-align: right;
    padding-right: 10px;
    color: gray;
    margin-top: 5px;
    cursor: pointer;
}

.Login-forgot:hover {
    color: green;
}

.Login-Or-Box {
    display: flex;
    width: 100%;
    align-items: center;
}

.Login-Or-Line {
    height: 1px;
    flex: 1;
    background-color: black;
}

.Login-Or-Text {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 4px;
}

.Login-faang {
    display: flex;
    width: 100%;
    justify-content:  center;
    gap: 50px;
    margin-top: 20px;
    cursor: pointer;
    gap: 10px;
}

@media only screen and (max-width: 768px) {
    .Login-Box {
        max-width: 90%;
        min-width: 90%;
    }
}