.profile-info-box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-info-avatar {
    width: 200px;
    height: 200px;
    border: 1px solid gray;
    border-radius: 50%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.profile-info-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    cursor: pointer;
}

.profile-input-avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
}

.profile-info{
    width: 60%;
    padding: 10px;
}

.profile-info-data {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    padding: 10px;
}

.profile-info-label {
    text-transform: uppercase;
    font-weight: bold;
    color: gray;
}

.profile-info-data-local{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
}

.profile-info-dropdown {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    position: relative;
}

.profile-info-input {
    width: 100%;
    height: 30px;
    outline: none;
    padding-left: 5px;
}

.profile-info-locals-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid gray;
}

.profile-dropdown-items {
    width: 100%;
    height: 200px;
    background: #EDEDED;
    position: absolute;
    top: 70px;
    left: 0;
    border-radius: 5px;
    overflow-y: scroll;
    padding: 5px;
    color: white;
    z-index: 100;
}

.profile-dropdown-items p {
    text-align: left;
    width: 90%;
    padding: 5px;
    background-color: #BCCEF8;
    color: #5C2E7E;
    border-radius: 5px;
    cursor: pointer;
}

.profile-dropdown-items p:hover {
    color: white;
    background-color: #372948
}

.profile-btn-update {
    padding: 10px;
    width: 100%;
    margin: auto;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 10px;
    background: #7B2869;
    color: white;
    transition: all 0.5s;
    text-align: center;
}

.profile-btn-update:hover {
    transform: translateY(-2px);
}

.profile-btn-update:active {
    transform: translateY(0);
}


@media (max-width: 500px) {
    .profile-info {
        width: 100%;
        padding: 0;
    }

    .profile-info-input {
        width: 90%;
    }

    .profile-btn-update {
        width: 90%;
    }

    .profile-info-data-local {
        flex-direction: column;
        gap: 10px;
    }

    .profile-info-dropdown {
        width: 90%;
    }
}