

.admin-passcheck {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    padding: 10px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.admin-passcheck .admin-input-box {
    width: 500px;
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px solid gray;
    padding: 10px 20px;
    gap: 10px;
}

.admin-input-box input {
    width: 100%;
    height: 30px;
    
}

.admin-input-box button {
    padding: 5px;
    cursor: pointer;
    margin: auto;
    width: 101.5%;
}

.admin-box{
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.admin-btn-box{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 5px;
}

.admin-btn-box button {
    padding: 10px;
    transition: all 0.5s;
    border: 1px solid gray;
    outline: none;
    border-radius: 3px;
}

.admin-btn-box button:hover {
    background-color: #852999;
    color: white;
    cursor: pointer;
    transform: translateY(-2px);
}

.admin-btn-box button:active {
    transform: translateY(0);
}

hr {
    width: 100%;
}

.admin-box span:nth-child(1){
    width: 100%;
    text-align: left;
}

.admin-list-item {
    border: 1px solid gray;
    border-radius: 3px;
    padding: 5px;
    width: 200px;
}

.admin-shop-title {
    font-weight: normal;
    font-size: 12px;
    margin-left: 1px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.admin-shop-ps {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: 5px 5px;
}

.admin-shop-ps span {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.admin-detail-shop {
    position: fixed;
    width: 80%;
    height: 80%;
    background-color: #393E46;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: hidden;
    overflow-y: scroll;
    border: 15px solid #FFD8A9;
    z-index: 200;
}

.admin-detail-input {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
    outline: none;
    margin-right: 20px;
    margin-top: 10px;
}

.admin-detail-input label {
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    color: white;
}

.admin-detail-input input {
    height: 30px;
    background-color: black;
    color: white;
}

.admin-detail-input textarea {
    background-color: black;
    color: white;
}

.admin-detail-ims-box {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    gap: 5px;
    margin-bottom: 10px;
}

.admin-detail-item-imgs {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 5px;
    color: white;
    padding: 5px;
}

.admin_shop_register_action {
    margin-top: 10px;
    height: 100px;
    width: 100%;
    color: white;
}

.admin-button-shop-action {
    display: flex;
    gap: 10px;
    justify-content: center;
    position: relative;
}

.admin-button-shop-action button {
    padding: 10px;
    cursor: pointer;
    background-color: #6D9886;
    color: white;
}

.admin-list-box {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.admin-list-item {
    margin: 5px;
}

.admin-button-shop-action button:active {
    transform: translateY(2px);
}

.admin-button-loading{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    opacity: 0.6;
    height: 60px;
    top: -15px;
}

.admin-loading {
    position: absolute;
    transform: translate(-50%);
    animation: rotate 2s infinite linear;
    display: flex;
    justify-content: center;
    align-self: center;
}

.btn-banner-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px;
    width: 100%;
}

.admin-btn-upload {
    min-width: 150px;
    height: 40px;
    position: relative;
    cursor: pointer;

}

.admin-btn-upload input {
    opacity: 0;
    width: 100%;
    height: 100%;
}

.admin-btn-upload div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    pointer-events: none;
}

.admin-btn-box-2 {
    display: flex;
    justify-content: flex-start;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
