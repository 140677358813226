

.myorder-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
    width: 100%; 
    height: 100%;
    position: relative;
    flex-wrap: wrap;
}

.myorder-top-box {
    width: 95%;
    display: flex;
    gap: 10px;
    padding: 10px;
}

.myorder-top-btn {
    padding: 10px;
    font-size: 20x;
    border: 1px solid gray;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    background-color: gray;
    color: white;
}

.myorder-orders-list {
    display: flex;
    justify-content: flex-start;
    min-width: 100%;
    position: relative;
    gap: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.myorder-detail-close-bottom {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    background-color: #495C83;
    color: white;
}


.myorder-items{ 
    padding: 5px;
    border: 1px solid gray;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 3px;
    transition: all 0.5s;
    cursor: pointer;
}


.myorder-item-time-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.myorder-time-item {
    font-size: 12px;
    color: gray;
}

.myorder-item-length {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: black;
    color: white;
    padding: 3px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myorder-img {
    margin-top: 5px;
    border-radius: 3px;
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
}

.myorder-data-box {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    gap: 5px;
}

.myorder-data-item-left {
    color: gray;
}

.myorder-data-item-end-left {
    font-size: 12px;
    color: gray;
    align-self: center;
}

.myorder-data-item-end-right {
    background-color: #083AA9;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.myorder-detail {
    width: 80%;
    height: 80%;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #F6F6F6;
    border-radius: 4px;
    z-index: 100;
    justify-content: space-between;
    overflow-y: auto;
}

.myoder-detail-close {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;
}

.myorder-detail-shop-title {
    margin-left: 5px;
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    text-align: center;
}

.myorder-detail-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.myorder-detail-item {
    padding: 5px;
    border: 1px solid black;
    width: 100%;
    display: flex;
    gap: 10px;
    box-sizing: border-box;

}

.myoder-detail-data-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.myorder-detail-data-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    padding: 5px;
    box-sizing: border-box;
    gap: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
}

.myorder-detail-data-item-left {
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.myorder-detail-mbs {
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: space-between;
}

.myorder-detail-mbs {
    background-color: #293462;
    flex: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    color: white;
    display: flex;
    padding: 5px;
    gap: 10px;
    flex-direction: column;
}

.myorder-detail-map-mbs {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.myorder-detail-mbs-box-1 {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 5px;
    border-bottom: 1px solid gray;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    transition: all 0.5s;
    transform-origin: top;
}

.myorder-detail-mbs-box-2 {
    padding: 5px;
    background-color: #CA4E79;
    cursor: pointer;
    border-radius: 25px;
}


.myorder-detail-mbs-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
}

.myorder-detail-data-bottom-item {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    box-sizing: border-box;
    font-weight: bold;

}

.myorder-detail-bottom-box {
    padding: 5px;
    box-sizing: border-box;
}

.myorder-detail-databottom-item-right {
    box-sizing: border-box;
    word-wrap: break-all;
}

.myorder-detail-item-img {
    width: 200px;
}

.order-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-width: 1000px) {
    .myorder-detail-data-bottom-item {
        flex-direction: column;
        word-break: break-all;
    }

    .myorder-detail-databottom-item-right {
        margin: 5px;
    }
}

@media only screen and (min-width: 1000px) {
    .myorder-items:hover {
        transform: scale(1.05);
    }
}

@media only screen and (max-width: 600px) {
    .myorder-detail-item {
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .myorder-top-box {
        justify-content: space-around;
    }

    .myorder-top-btn {
        align-self: flex-end;
        width: 50%;
    }

    .myorder-orders-list {
        width: 100%;
        justify-content: center;
        gap: 5px;
        max-width: max-content;
        max-height: max-content;
    }

    .myorder-detail {
        width: 100%;
        height: 100%;
    }

    .myorder-items {
        min-width: 45%;
        max-width: 45%;
    }

    .myorder-data-box:nth-last-child(1) {
        flex-direction: column;
        text-align: center;
    }
    
    .myorder-img {
        margin-top: 5px;
        border-radius: 3px;
        min-width: 100%;
        min-height: 150px;
        max-width: 100%;
        max-height: 150px;
    }

    .myoder-detail-data-box {
        width: 100%;
    }
}