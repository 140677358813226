body {
    background-color: white;
}

.shop-detail-box {
    padding: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.shop-detail-top-box {
    padding: 5px;
    display: flex;
    gap: 20px;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
}

.shop-detail-top-img {
    max-width: 400px;
    max-height: 400px;
    min-width: 350px;
    min-height: 350px;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.shop-detail-link-box{ 
    display: flex;
    padding: 5px;
    gap: 5px;
}

.shop-detail-link-item {
    color: gray;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.shop-detail-link-item:hover {
    color: #1D1CE5
}

.shop-detail-top-mid {
    flex: 1;
    min-width: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.shop-detail-top-last {
    flex: 1;
    min-width: 300px;
    border-radius: 4px;
    min-height: 350px;
    height: 400px;
    margin-right: 10px;
}

.shop-detail-content-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.dg {
    margin-right: 5px;
}

.shop-detail-seller{
    width: 100%;
    position: relative;
}

.shop-detail-seller-local-box {
    width: 100%;
    display: flex;
    gap: 5px;
    margin-top: 4px;
}

.shop-detail-seller-local-box span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: #8758FF;
}


.shop-detail-address {
    width: 100%;
    text-align: left;
    margin-top: 4px;
}

.shop-detail-address-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.shop-detail-address-input input {
    width: 100%;
    height: 25px;
    outline: none;
    cursor: pointer;

}

.shop-detail-buyer-local-box {
    display: flex;
    gap: 5px;
    width: 100%;
}

.shop-detail-dropdown-local {
    display: flex;
    justify-content: space-between;
    border: 1px solid gray;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    align-items: center;
    padding-right: 5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shop-detail-local-items {
    position: absolute;
    width: 100%;
    max-height: 300px;
    background-color: #3F4E4F;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 20;
    align-self: flex-start;
    justify-content: flex-start;
    gap: 5px;
    color: white;
    top: 30px;
    left: -2px;
    border-radius: 2px;
}

.dt-current-local {
    flex: 1;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.shop-detail-local-item {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;
    padding: 5px;
}

.shop-detail-local-item:hover {
    background: white;
    color: black;
}

.shop-detail-ship-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.shop-detail-ship-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.shop-detail-share-icons {
    display: flex;
    gap: 5px;
}

.shop-detail-icon {
    font-size: 25px;
    cursor: pointer;
}

.shop-detail-icon:hover {
    color: orangered;
}

.shop-detail-top-last {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.order-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.shop-detail-order {
    width: 100%;
    background-color: chocolate;
    padding: 10px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    text-align: center;
    margin-left: 5px;
}

.order-money-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
    text-align: left;
}

.order-money-box div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.shop-detail-list-menu-box {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid grey;
    margin: 20px 5px 0 5px;
}

.dt-share {
    font-weight: bold;
    color: gray;
}

.shop-detail-menu-dropdown-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
    min-width: 150px;
    border: 1px solid grey;
    border-bottom: none;
    border-top-left-radius: 40px;
    height: 100%;
    padding: 20px;
    cursor: pointer;
    gap: 10px;
    margin: auto;
    position: relative;
    text-align: center;
}

.shop-detail-menu-dropdown-box span {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.menu-item {
    position: absolute;
    background-color: #2B3A55;
    width: 100%;
    height: 400px;
    top: 70px;
    z-index: 100;
    left: 0;
    border-radius: 4px;
    overflow: auto
}

.menu-item div {
    text-align: left;
    padding: 5px;
    color: white;
    border-bottom: 1px solid gray;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-item div:hover {
    color: orangered;
}

.shop-detail-menu-slide {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.dt-arrow-menu {
    padding: 5px;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    color: brown;
    z-index: 40;
    margin-right: 10px;
}

.dt-menu-box {
    position: relative;
    display: flex;
    flex: 1;
    height: 40px;
    overflow: hidden;
    text-align: center;
}

.dt-menu-item {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 20%;
    max-width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.dt-menu-text {
    display: block;
    cursor: pointer;
    border-radius: 3px;
    min-width: 80%;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
    border: 1px solid gray;
    margin: 4px;
}

.dt-menu-text:hover {
    background-color: #404258;
    color: white;
}

.saveMenus-box {
    height: 450px;
    position: relative;
}

/* main items box */
.dt-main-items-box {
    width: 97.5%;
    display: flex;
    overflow: auto;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
    position: relative;
    min-height: 100%;
    padding-left: 10px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .5s;
}


.dt-main-item {
    display: flex;
    padding: 5px;
    min-width: 200px;
    max-width: 16%;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 3px;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    transition: all 0.25s;
}

.dt-item-box {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    position: relative;
}

.dt-tongtien {
    position: absolute;
    bottom: 0;
    background-color: black;
    left: -4.5px;
    width: 100%;
    opacity: 0.8;
    padding: 5px;
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

}

.dt-tongtien span:nth-child(2) {
    font-weight: bold;
    color: orangered
}

.dt-img {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
}

.dt-nameshop {
    text-align: center;
    margin: 5px;
    font-size: 13px;
    font-weight: bold;
    color: gray;
}

.dt-item-text-box {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

.dt-item-text-box span:nth-child(1) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: max-content;
    color: gray;
}

.dt-item-text-box span:nth-child(2) {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dt-hr {
    margin: 0;
    padding: 0;
}

.dt-item-pl{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    overflow: auto;
    transition: all 0.5s;   
    transform-origin: top;
    z-index: 20;
}

.dt-item-pl-box {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.dt-item-pl-item {
    display: flex;
    width: 100%;
    border-bottom: 1px solid gray;
}

.dt-item-pl-item-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    gap: 3px;
    font-size: 13px;
    flex: 1;
}

.dt-addtocart-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.dt-addtocart-box div {
    display: flex;
    gap: 5px;
    align-items: center;
}


.dt-item-pl-item-text div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
}

.dt-effectSelectItems {
    transform: scale(1.05);
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.7);
}

.dt-checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 30px;
    margin-right: 5px;
}

.dt-item-pl-item-close {
    align-items: flex-end;
    border-radius: none;
    padding: 5px;
    cursor: pointer;
    background-color: #2192FF;
    color: white;
    border: none;
}

.dt-min-plus {
    font-size: 25px;
    cursor: pointer;
    transition: all 0.5s;
}

.dt-addtocart-text {
    transition: all 0.5s;
    padding: 3px 15px;
    background-color: #65647C;
    border-radius: 3px;
    color: white;
    font-size: 14px;
}

.dt-addtocart-text:hover {
    background-color: #377D71;
    transform: translateY(-2px);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4);
}

.dt-addtocart-text:active {
    transform: translateY(0);
}

.dt-min-plus:hover{
    box-shadow: 0 0 5px 5px rgba(123,222, 55, 0.7)
}

.order-item-list {
    flex: 1;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
}

.order-item {
    width: 100%;
    border: 1px solid gray;
    flex-direction: column;
    margin-left: 10px;
    padding: 5px;
    border-radius: 3px;
    position: relative;
    justify-content: space-between;
    transition: all 0.5s;
    cursor: pointer;
}


.order-item-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.order-item-content span:nth-child(1) {
    color: gray;

}

.order-item-content span:nth-child(2) {
    font-weight: bold;
}

.order-item:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.7);
}

.order-item-monbosung {
    display: flex;
    gap: 10px;
}

.order-mbs-box {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid gray;
    margin-top: 5px;
    cursor: pointer;
    color: white;
    border-radius: 3px;
    margin-right: 5px;
    background-color: black;
    
}

.order-item-info {
    display: flex;
    gap: 5px;
    flex: 1;
}

.order-item-info img {
    width: 150px;
    height: 150px;
}

.order-items-monbosung {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    color: red;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    transition: all 0.5s;
    transform-origin: right;
}

.dt-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.dt-order-mbs-item {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    width: 95%;
    align-items: center;
}

.dt-mbs-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
}

.dt-order-delete-mbs {
    font-size: 25px;
    color: gray;
    border-radius: 3px;
    cursor: pointer;
}

.dt-order-delete-mbs:hover {
    color: #F06292;
}

.dt-order-mbs-close {
    padding: 5px;
    width: 95%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: bottom;
    border: 1px solid gray;
    background-color: gray;
    color: white;
    top: 0;
    max-height: 20px;
    opacity: 0.7;
    left: -20px;
    cursor: pointer;
    margin-bottom: 5px;
    border-radius: 4px;
    margin-top: 5px;
}

.order-mbs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 5px;
}

.dt-order-mbs-close:hover {
    opacity: 100;
    transform: translateY(-2px);
    background-color: #1746A2
}

.dt-tb {
    display: flex;
    flex-direction: column;
}

.dt-tb div {
    display: flex;
    align-items: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid gray;
    width: max-content
}

.dt-tb span {
    padding: 5px;
    border: none;
    font-size: 18px;
    outline: none;
    text-align: left;
}

.delete-order-item {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid gray;
    margin-top: 5px;
    cursor: pointer;
    color: white;
    background-color: #3F4E4F;
    border-radius: 3px;
    font-size: 18.5px;
    transition: all 0.5s;
}

.delete-order-item:hover {
    box-shadow: 0 0 5px 5px rgba(22,11, 88, 0.7);
    transform: translateY(-2px);
}
.dt-text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dt-des {
    white-space: pre-wrap;
    padding: 5px;
}

.dt-review-box {
    width: 100%;
    margin: auto;
}

.dt-review {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    border: 1px solid gray;
    gap: 10px;
    border-radius: 8px;
    margin-top: 30px;
    background-color: #EEEEEE
}

.dt-review textarea {
    width: 99%;
    padding: 5px;
}

.dt-review-text {
    margin-top: 10px;
    font-weight: bold;
}

.dt-gdg {
    padding: 10px;
    cursor: pointer;
    background-color: #395144;
    color: white;
}

.review-list {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 99%;
    margin: auto;
    margin-top: 10px;
}

.review-list button {
    padding: 15px;
    cursor: pointer;
    color: white;
    background-color: #9A1663;
    border: none;
    border-radius: 2px;
    font-size: 20px;
    transition: all 0.5s;
    width: 100%;
}

.review-list button:hover {
    box-shadow: 0 0 5px 5px rgba(120, 120, 180, 0.7);
    transform: translateY(-2px);
}

.review-list button:active {
    transform: translateY(0);
}

.review-item {
    width: 100%;
    margin-top: 15px;
    flex: 1;
    padding: 5px;
    display: flex;
    gap: 15px;
    border: 1px solid gray;
    max-height: 150px;
    border-radius: 4px;
    background-color: #EEF1FF;
}

.review-av {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid gray;
}


.review-text-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

.review-content {
    flex: 1;
    width: 100.5%;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    border-bottom: 1px solid gray;
}

.review-bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 5px 0;
    box-sizing: border-box;
}

.dt-rvpage-box {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.dt-page-item {
    padding: 15px;
    font-size: 20px;
    border: 1px solid gray;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: gray; 
    color: white;
}

.dt-arrow-page {
    cursor: pointer;
    color: #9C254D;
    margin: 0 15px;
    transition: all 0.5s;
}

.dt-arrow-page:hover {
    background-color: #C060A1;
    color: white;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 800px) {
    .shop-detail-list-menu-box {
        flex-direction: column;
        border: none;
    }

    .detail-menu-icon {
        margin-right: 5px;
    }

    
    .shop-detail-menu-dropdown-box {
        border: none;
        padding: 10px 0px 10px 0px;
        display: flex;
        border-radius: 20px;
        justify-content: space-between;
        background-color: #F06292;
        color: white;
        width: 100%;
    }
    .shop-detail-menu-slide {
        display: none;
    }
    .dt-share {
        display: none;
        visibility: hidden;
    }

    .dt-page-item {
        display: none;
        opacity: 0;
        visibility: hidden;
    }

    .review-item {
        max-height: max-content;
        align-items: center;
    }

    .review-content {
        max-height: max-content;
    }

    .review-av {
        width: 50px;
        height: 50px;
        padding: 5px;
        box-sizing: border-box
    }
}

@media only screen and (max-width: 500px) {
    .order-mbs-box{
        flex-direction: column;
    }

    .review-av {
        width: 100px;
        height: 100px;
    }

    .review-av img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    
}