.footer-box {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.footer-box-1-1{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    padding: 5px
}


.footer-box-1-1-text {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
}

.footer-box-1-1-line {
    flex: 1;
    height: 3px;
    background-color: gray;
    margin-left: 5px;
    margin-right: 10px
}

.footer-box-2 {
    border: 1px solid gray;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    height: 200px;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.footer-box-3 {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer-box-3-item {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.footer-box-3-item div {
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: 100%;
}

.footer-text-style {
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
    width: 100%;
    text-align: left;
}

.footer-text-style:hover {
    color: #DC5F00;
}

.footer-text-style:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    background-color: #DC5F00;
    transform-origin: bottom left;
    transform: scale(0);
    transition: all 0.5s;

}

.footer-text-style:hover:before {
    transform: scale(1);
}

.footer-box-4 {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px;
}

.footer-box-4 div {
    flex: 1;
}

.footer-box-4-bot {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 80px;
    cursor: pointer;
}

.footer-cl {
    flex-direction: row;
}

@media only screen and (max-width: 1000px) {
    .footer-box-3 {
        display: none;
        visibility: hidden;
        opacity: 0;
    }
}

@media only screen and (max-width: 768px) {
    .footer-box-4 {
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
    }

    .footer-box-4-bot {
       display: flex;
    }
    
    .app-image {
        width: 30%;
        height: 50px;
    }

}

.foot-wlt {
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 1000px){
    .footer-box-2 {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    .footer-box-1 {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
}
